import moment from 'moment'

import { DD_MM_YYYY_PARSE, HH_MM_SS_PARSE } from '../../../constants/date'

/**
 * Convert date to string
 *
 * @param {string} date - Date to convert
 * @returns {object} - Converted date
 */
export const convertDate = (
  date,
  pattern = DD_MM_YYYY_PARSE,
  resultPattern = null
) => {
  return date ? moment(date, pattern).format(resultPattern) : null
}

/**
 * Convert hour to string
 *
 * @param {string} hour - Hour to convert
 * @param {object} - Converted hour
 */
export const convertHour = (hour, pattern = HH_MM_SS_PARSE) => {
  return hour ? moment(hour, pattern).format() : null
}

/**
 * Checks if date is between two dates
 *
 * @param {string} dateCheck - date to verify
 * @param {string} date1 - date1
 * @param {string} date2 - date2
 */
export const hasDateBetween = (dateCheck, date1, date2) => {
  return (dateCheck && date1 && date2) ? moment(dateCheck).isBetween(moment(date1), moment(date2), 'days', '[]') : null
}