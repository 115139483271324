import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(
      URL.activity + '/filter',
      param
    )

    return dispatch(trigger(types.activity.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.activity.getFilter.error, error))
  }
}

export const findWithExpectedDelivery = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(
      URL.activity + '/findWithExpectedDelivery',
      param
    )

    return dispatch(trigger(types.activity.getUnexpiredActivities.success, response.data))
  } catch (error) {
    return dispatch(trigger(types.activity.getUnexpiredActivities.error, error))
  }
}

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.activity, param)

    return dispatch(trigger(types.activity.get.success, response.data))
  } catch (error) {
    return dispatch(trigger(types.activity.get.error, error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.activity, param)

    return dispatch(trigger(types.activity.save.success))
  } catch (error) {
    return dispatch(trigger(types.activity.save.error, error))
  }
}

export const remove = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.activity, param)

    return dispatch(trigger(types.activity.delete.success))
  } catch (error) {
    return dispatch(trigger(types.activity.delete.error, error))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.activity, param)

    return dispatch(trigger(types.activity.update.success))
  } catch (error) {
    return dispatch(trigger(types.activity.update.error, error))
  }
}

export const getUserCurrentTask = id => async dispatch => {
  console.error('DESENVOLVER A ACTIONS PARA A TELA USER TASK')
}

export const getActivityHistory = (params, errorCallback) => dispatch => {
  return new Http(dispatch).get(`${URL.statusHistory}`).then(
    data => {
      dispatch({
        type: types.activity.getHistory.success,
        payload: data && data.data,
      })
    },
    err => {
      errorCallback && errorCallback()
      dispatch({
        type: types.activity.getHistory.failure,
      })
    }
  )
}

export const importTasks = data => dispatch => {
  return new Http(dispatch).post(`${URL.activityImport}`, data)
}

export const clearMessages = () => dispatch => {
  dispatch(trigger(types.activity.clearMessages))
}
