import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { Create as CreateIcon } from '@material-ui/icons'

import * as actionEntryReport from '../../actions/entry'
import * as actionActivity from '../../actions/activity'
import * as actionStatusHistory from '../../actions/statusHistory'
import { get as getUsers } from '../../actions/user'
import { get as getClients } from '../../actions/client'
import { get as getProjects } from '../../actions/project'
import { get as getPeriods } from '../../actions/period'
import { markFilterAsValidToApply, setFilter } from '../../actions/entryReport'
import useStyles from './useStyles'

import { globalStyles } from '../../styles/global'
import Main from '../Main'
import TablePagination from '../../utils/Table/TableNoPagination'
import { Box, Grid, Paper, TableCell, TableContainer } from '@material-ui/core'
import Filter from '../../utils/Filter/Filter'

import Loading from '../../utils/Loading'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'
import { getObjectFromParams, getParamsFromObject } from '../../utils/Params'
import { useHistory } from 'react-router-dom'

const SORT = 'dthLancamento'

const TABLE_HEADER = ['Data', 'Dia', 'Horas', 'Ações']

const presentOrder = ['data', 'dayWeek', 'hours']

const optionsSobreAviso = [
  { label: 'Não', value: 'false' },
  { label: 'Sim', value: 'true' },
]

const EntryReport = ({
  /** Actions */
  getClients,
  getProjects,
  getActivities,
  getReport,
  getPeriods,
  setFilterContext,
  getUsers,
  listUsers,
  /** Reducer props */
  listClients,
  listFilterEntry,
  listProjects,
  listUnexpiredActivities,
  totalElementsEntry,
  totalHours,
  entryReportReducer,
}) => {
  momentDurationFormatSetup(moment)
  const user = JSON.parse(localStorage.getItem('user'))
  const { id: idUsuario } = JSON.parse(localStorage.getItem('user'))
  const styles = globalStyles()
  const history = useHistory()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)
  const [totalColumnTimeFormatted, setTotalColumnTimeFormatted] = useState('')
  const lastSelectedClientId = useRef('')
  const lastSelectedProjectId = useRef('')

  const [showLoading, setShowLoading] = useState(false)

  const [selectState, setSelectState] = useState({ fields: [] })

  const [entries, setEntries] = useState([])

  const [valuesFilter, setValuesFilter] = useState({
    idUser: idUsuario,
    dtInicial: moment().clone().startOf('month').format('LL'),
    dtFinal: moment().clone().endOf('month').format('LL'),
  })

  const classes = useStyles()

  const [iniDateFilter, setIniDateFilter] = useState(new Date(2000, 1, 1))
  const [endDateFilter, setEndDateFilter] = useState(new Date(2100, 1, 1))

  const handleFilter = values => {
    const {
      idClient,
      idProject,
      sigAtividade,
      idUser,
      indSobreaviso,
    } = values

    const dtInicial = moment(values.dtInicial).isValid()
      ? moment.utc(values.dtInicial).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    const dtFinal = moment(values.dtFinal).isValid()
      ? moment.utc(values.dtFinal).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    const filter = {
      ...values,
      dtInicial: dtInicial,
      dtFinal: dtFinal,
    }

    setFilterContext(filter)

    setValuesFilter(filter)

    setIniDateFilter(values.dtInicial || undefined)
    setEndDateFilter(values.dtFinal || undefined)

    getReport({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: size,
      ...(idClient && { idClient }),
      ...(idProject && { idProject }),
      ...(sigAtividade && { sigActivity: sigAtividade }),
      ...(idUser && { idUser }),
      ...(dtInicial && { dtInicial }),
      ...(dtFinal && { dtFinal }),
      ...(indSobreaviso && { indSobreaviso }),
    })

    setPage(DEFAULT_PAGINATION_PAGE)
  }

  const parsedParams = getObjectFromParams(window.location.search)

  const handleChangeFilterState = state => {
    if (
      !state.fields.idClient ||
      state.fields.idClient !== lastSelectedClientId.current
    ) {
      state.fields.idProject = null
      state.fields.sigAtividade = null
    }

    if (
      state.fields.idProject &&
      state.fields.idProject !== lastSelectedProjectId.current
    ) {
      state.fields.sigAtividade = null
    }

    setSelectState(state)
    lastSelectedClientId.current = state.fields.idClient
    lastSelectedProjectId.current = state.fields.idProject
  }

  const uniqueOptions = new Set();
const options = listUnexpiredActivities
  .filter(activity => {
    if (selectState.fields.idProject) {
      return activity.projectId === selectState.fields.idProject;
    }
    if (selectState.fields.idClient) {
      const client = listClients.find(it => it.id === selectState.fields.idClient);
      return !!client && activity.descClient === client.descCliente;
    }
    return true;
  })
  .map(activity => {
    if (activity) {
      const sigAtividadeTrimmed = activity.sigAtividade.trim();
      if (!uniqueOptions.has(sigAtividadeTrimmed)) {
        uniqueOptions.add(sigAtividadeTrimmed);
        return {
          label: sigAtividadeTrimmed,
          value: sigAtividadeTrimmed,
        };
      }
    }
    return null;
  })
  .filter(option => option !== null);

  let filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'idClient',
        label: 'Cliente',
        initial: valuesFilter.idClient,
        options: (listClients || []).map(({ id, descCliente }) => ({
          label: descCliente,
          value: id,
        })),
      },
      {
        type: 'select',
        name: 'idProject',
        label: 'Projeto',
        initial: valuesFilter.idProject,
        options: (listProjects || [])
          .filter(project => project.idClient === selectState.fields.idClient)
          .map(({ id, desProjeto }) => ({
            label: desProjeto,
            value: id,
          })),
        disabled: !selectState.fields.idClient,
      },
      {
        type: 'select',
        name: 'sigAtividade',
        label: 'Sigla Atividades',
        initial: valuesFilter.sigAtividade,
        options: options,
        disabled: !selectState.fields.idClient,
      },
      {
        type: 'select',
        name: 'idUser',
        label: 'Usuário',
        initial: valuesFilter.idUsuario ? valuesFilter.idUsuario : valuesFilter.idUser,
        options: (listUsers || [])
          .sort((userA, userB) =>
            userA.desUsuario.localeCompare(userB.desUsuario)
          )
          .map(({ id, desUsuario }) => ({
            label: desUsuario,
            value: id,
          })),
        disabled: user.office.tipCargo === 'OPERAÇÃO',
      },
    ],

    fieldsLine2: [
      {
        type: 'select',
        name: 'indSobreaviso',
        label: 'Sobreaviso',
        initial: valuesFilter.indSobreaviso,
        options: optionsSobreAviso,
      },
      {
        type: 'date',
        name: 'dtInicial',
        label: 'Data inicial',
        initial: valuesFilter.dtInicial
          ? moment(valuesFilter.dtInicial).format()
          : valuesFilter.dtInicial,
        maxDate: endDateFilter,
        onChange: e => setIniDateFilter(e || null),
      },
      {
        type: 'date',
        name: 'dtFinal',
        label: 'Data final',
        initial: valuesFilter.dtFinal
          ? moment(valuesFilter.dtFinal).format()
          : valuesFilter.dtFinal,
        minDate: iniDateFilter,
        onChange: e => setEndDateFilter(e || null),
      },
    ],

    monitoreState: handleChangeFilterState,
  }

  const changePage = page => {
    const {
      idClient,
      idProject,
      sigAtividade,
      idUser,
    } = valuesFilter

    const dtInicial = moment(valuesFilter.dtInicial).isValid()
      ? moment.utc(valuesFilter.dtInicial).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    const dtFinal = moment(valuesFilter.dtFinal).isValid()
      ? moment.utc(valuesFilter.dtFinal).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    setIniDateFilter(valuesFilter.dtInicial || undefined)
    setEndDateFilter(valuesFilter.dtFinal || undefined)

    setPage(page)
    getReport({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idClient && { idClient }),
      ...(idProject && { idProject }),
      ...(sigAtividade && { sigAtividade }),
      ...(idUser && { idUser }),
      ...(dtInicial && { dtInicial }),
      ...(dtFinal && { dtFinal }),
    })
  }

  const changeSize = size => {
    const {
      idClient,
      idProject,
      sigAtividade,
      idUser,
    } = valuesFilter

    const dtInicial = moment(valuesFilter.dtInicial).isValid()
      ? moment.utc(valuesFilter.dtInicial).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    const dtFinal = moment(valuesFilter.dtFinal).isValid()
      ? moment.utc(valuesFilter.dtFinal).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    setIniDateFilter(valuesFilter.dtInicial || undefined)
    setEndDateFilter(valuesFilter.dtFinal || undefined)

    setSize(size)
    getReport({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idClient && { idClient }),
      ...(idProject && { idProject }),
      ...(sigAtividade && { sigAtividade }),
      ...(idUser && { idUser }),
      ...(dtInicial && { dtInicial }),
      ...(dtFinal && { dtFinal }),
    })
  }

  useEffect(() => {
    setShowLoading(true)

    getClients()
    getProjects()
    getPeriods()

    getActivities().then(() => {
      setShowLoading(false)
    })
  }, [])

  useEffect(() => {
    if (entryReportReducer.shouldApplyFilter) {
      handleFilter(entryReportReducer.filter)
    } else if (Object.keys(valuesFilter).length !== 0) {
      handleFilter(valuesFilter)
    }
  }, [])

  useEffect(() => {
    setEntries(listFilterEntry)
  }, [listFilterEntry])

  useEffect(() => {
    setTotalColumnTimeFormatted(totalHours)
  }, [entries])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  const onClickRowAction = row => {
    const { data } = row
    const momentDate = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD')
    const params = getParamsFromObject({
      ...valuesFilter,
      dtInicial: momentDate,
      dtFinal: momentDate,
      fromEntryReport: true,
    })
    history.push(`/entries?${params}`)
  }

  return (
    <Main title="Consolidado">
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <Grid item xs={12}>
            <Box component="div" className={styles.box} xs={12}></Box>
            <Box component="div" className={styles.filter} xs={12}>
              <Filter {...filterProps} gridSizeLine2={3} />
            </Box>
            <TableContainer component={Paper}>
              <TablePagination
                isFromEntry={parsedParams.fromEntryReport ? false : true}
                titleTable="listFilterEntry"
                count={totalElementsEntry}
                headers={TABLE_HEADER}
                onChangePage={(_, page) => changePage(page)}
                onChangeSize={event => changeSize(event.target.value)}
                page={page}
                rows={entries}
                size={size}
                order={presentOrder}
                customFooter={
                  entries.length !== 0 ? (
                    <>
                      <TableCell colSpan={2} />
                      <TableCell className={classes.totalTime}>
                        <span>Tempo total: </span>
                        {totalColumnTimeFormatted}
                      </TableCell>
                      <TableCell />
                    </>
                  ) : (
                    ''
                  )
                }
                alignTitle={['center', 'center', 'center', 'center']}
                alignItens={['center', 'center', 'center', 'center']}
                rowActionClassName={classes.rowAction}
                rowAction={
                  <div className={classes.rowDetail}>
                    Detalhes <CreateIcon />
                  </div>
                }
                onClickRowAction={onClickRowAction}
              />
            </TableContainer>
          </Grid>
        </>
      )}
    </Main>
  )
}

const mapStateToProps = state => {
  const {
    activityReducer,
    loginReducer,
    clientReducer,
    projectReducer,
    userReducer,
    statusHistoryReducer,
    periodReducer,
    entryReducer,
    entryReportReducer,
  } = state

  return {
    ...activityReducer,
    ...loginReducer,
    ...clientReducer,
    ...projectReducer,
    ...userReducer,
    ...statusHistoryReducer,
    ...periodReducer,
    ...entryReducer,
    entryReportReducer,
    listUsers: userReducer.listUsers,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionEntryReport,
      setFilterContext: setFilter,
      markFilterAsValidToApply,
      getActivities: actionActivity.findWithExpectedDelivery,
      saveActivities: actionActivity.update,
      saveStatusHistory: actionStatusHistory.save,
      getClients,
      getProjects,
      getPeriods,
      getUsers,
    },
    dispatch
  )

EntryReport.propTypes = {
  /** Actions */
  getUsers: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  getActivities: PropTypes.func.isRequired,
  getPeriods: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  setFilterContext: PropTypes.func.isRequired,
  markFilterAsValidToApply: PropTypes.func.isRequired,
  /** Reducer props */
  listClients: PropTypes.array.isRequired,
  listProjects: PropTypes.array.isRequired,
  listUnexpiredActivities: PropTypes.array.isRequired,
  listFilterEntry: PropTypes.array.isRequired,
  listPeriods: PropTypes.array.isRequired,
  success: PropTypes.string,
  totalElementsEntry: PropTypes.number.isRequired,
  entryReportReducer: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryReport)
