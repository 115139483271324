import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  listActivities: [],
  listUnexpiredActivities: [],

  listAllActivities: [],

  listActivitiesFilter: [],

  errorActivities: null,
  successActivities: null,

  totalElementsActivities: 0,
  totalElementsActivityHistory: 0,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.activity.getFilter.success:
      const { resultList, pages } = action.payload

      return {
        ...state,
        listActivitiesFilter: resultList || [],
        totalElementsActivities: pages.totalCount || resultList.length,
      }
    case types.activity.getFilter.error:
      return {
        ...state,
        errorActivities: 'Não foi encotrado resultados para essa filtragem',
        successActivities: null,
      }

    case types.activity.get.success:
      return {
        ...state,
        listActivities: action.payload.content || action.payload,
      }

    case types.activity.get.error:
      return {
        ...state,
        errorActivities: 'Erro ao buscar Atividades',
        successActivities: null,
      }

    case types.activity.getUnexpiredActivities.success:
      return {
        ...state,
        listUnexpiredActivities: action.payload.content || action.payload,
      }

    case types.activity.getUnexpiredActivities.error:
      return {
        ...state,
        errorActivities: 'Erro ao buscar Atividades',
        successActivities: null,
      }

    case types.activity.save.success:
      return {
        ...state,
        successActivities: 'Atividade salva com sucesso',
        errorActivities: null,
      }

    case types.activity.save.error:
      return {
        ...state,
        errorActivities: 'Erro ao salvar Atividade',
        successActivities: null,
      }

    case types.activity.delete.success:
      return {
        ...state,
        successActivities: 'Atividade deletada com sucesso',
        errorActivities: null,
      }

    case types.activity.delete.error:
      const { messageError } = action.payload.response.data.message

      return {
        ...state,
        errorActivities: messageError,
        successActivities: null,
      }

    case types.activity.update.success:
      return {
        ...state,
        successActivities: 'Atividade atualizada com sucesso',
        errorActivities: null,
      }

    case types.activity.update.error:
      return {
        ...state,
        errorActivities: 'Erro ao atualizar Atividade',
        successActivities: null,
      }

    case types.activity.filter.success:
      return {
        ...state,
        error: false,
        activityHistoryFilter: action.payload.content || action.payload,
      }

    case types.activity.filter.error:
      return {
        ...state,
        errorActivities: 'Erro ao filtrar',
        successActivities: null,
      }

    case types.activity.getHistory.success:
      return {
        ...state,
        error: false,
        activityHistory: action.payload.content || action.payload,
        totalElementsActivityHistory: action.payload.totalElements || 0,
      }

    case types.activity.getHistory.failure:
      return {
        ...state,
        error: true,
        activityHistory: null,
      }

    case types.activity.clearMessages:
      return {
        ...state,
        errorActivities: '',
        successActivities: '',
      }

    default:
      return state
  }
}
