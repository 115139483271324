import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { useFormik } from 'formik'
import TextField from '@material-ui/core/TextField'
import Datepicker from '../../../utils/Form/Datepicker'
import Button from '../../../utils/Form/Button'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import { validaCPF } from '../../../utils/validaCpf'
import moment from 'moment'
import createSyntheticEvent from '../../../utils/createSyntheticEvent'
import MenuItem from '@material-ui/core/MenuItem'
import Mask from 'vanilla-masker'
import { makeStyles } from '@material-ui/core/styles'
import SelectAutocompleteField from './SelectedAutoCompletedField'
import {
  capitalizeFirstLetterOfEachWord,
  formatTextUser,
  formatarCargo,
  removeSpecialCharacters
} from '../utils/FormatText'
import api from "../../../../server"


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
      decimalSeparator=","
      prefix="R$ "
      thousandSeparator="."
      isNumericString={true}
      maxLength={24}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

function NumberFormatCustomCoeficiente(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      fixedDecimalScale={true}
      decimalScale={6}
      decimalSeparator=","
      isNumericString={true}
      maxLength={30}
    />
  )
}

NumberFormatCustomCoeficiente.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const birthdayDateValidator = date => {
  if (!date) return true
  const diff = moment(date).diff(new Date(), 'days')
  const isBirthDateBeforeToday = diff < 0
  return isBirthDateBeforeToday
}



const createPhoneNumberMask = (phone = '') => {
  const phoneNumbers = phone.replace(/\D/g, '')
  const maskPattern =
    phoneNumbers.length > 10 ? '(99) 99999-9999' : '(99) 9999-9999'
  return Mask.toPattern(phone, maskPattern)
}

const validatePhoneRegex = /\(\d{2}\) ?\d{4,5}-\d{4}/g



const validationSchemaSave = yup.object({
  birthdayDate: yup
    .date()
    .typeError('Data inválida!')
    .nullable()
    .test({
      message: 'Data deve ser anterior à data de hoje!',
      test: birthdayDateValidator,
    })
    .required('Informe uma data válida!'),
  startDateJob: yup
    .date()
    .typeError('Data inválida!')
    .nullable()
    .required('Informe uma data válida!'),
  endDateJob: yup
    .date()
    .nullable()
    .typeError('Data inválida!')
    .test({
      message: 'A data de demissão não pode ser anterior a data de admissão',
      test: (endDateJob, ctx) => {
        if (!ctx.parent.startDateJob || !ctx.parent.endDateJob) return true
        return moment(endDateJob).isAfter(ctx.parent.startDateJob)
      },
    }),
  name: yup
    .string()
    .min(10, 'O campo deve conter no minimo 10 caracteres')
    .required('Nome é obrigatório!'),
  email: yup
    .string()
    .email('Por favor, informe um e-mail válido!')
    .max(50, 'O e-mail deve conter no máximo 50 caracteres!')
    .required('E-mail é obrigatório'),
  email2: yup
    .string()
    .email('Por favor, informe um e-mail válido!')
    .max(50, 'O e-mail deve conter no máximo 50 caracteres!'),
  specialty: yup
    .string()
    .max(100, 'O campo deve conter no máximo 100 caracteres.'),
  cpf: yup
    .string()
    .test({
      message: 'CPF inválido',
      test: cpf => validaCPF(cpf),
    })
    .required('CPF é obrigatório!'),
  rg: yup.string().required('RG é obrigatório!'),
  seniority: yup.string().nullable(),
  educationLevel: yup.string().nullable(),
  phone: yup
    .string()
    .matches(validatePhoneRegex, {
      excludeEmptyString: true,
      message: 'Telefone inválido',
    })
    .required('Telefone 01 é obrigatório!'),
  phone2: yup.string().matches(validatePhoneRegex, {
    excludeEmptyString: true,
    message: 'Telefone inválido',
  }),
  phone3: yup.string().matches(validatePhoneRegex, {
    excludeEmptyString: true,
    message: 'Telefone inválido',
  }),
  phoneEmergency: yup.string().matches(validatePhoneRegex, {
    excludeEmptyString: true,
    message: 'Telefone inválido',
  }),
  address: yup.string().required('Endereço é obrigatório!'),
  district: yup.string().required('Bairro é obrigatório!'),
  cep: yup.string().required('CEP é obrigatório!'),
  city: yup.string().required('Cidade é obrigatório!'),
  typeContract: yup.string().nullable(),
  stateEnum: yup.string().required('Estado obrigatório!'),
  idOffice: yup.string().required('Cargo é obrigatório!'),
})

const validationSchemaEdit = yup.object({
  birthdayDate: yup
    .date()
    .typeError('Data inválida!')
    .nullable()
    .test({
      message: 'Data deve ser anterior à data de hoje!',
      test: birthdayDateValidator,
    })
    .required('Informe uma data válida!'),

  startDateJob: yup
    .date()
    .typeError('Data inválida!')
    .nullable()
    .required('Informe uma data válida!'),
  endDateJob: yup
    .date()
    .typeError('Data inválida!')
    .test({
      message: 'A data de demissão não pode ser anterior a data de admissão',
      test: (endDateJob, ctx) => {
        if (!ctx.parent.startDateJob || !ctx.parent.endDateJob) return true
        return moment(endDateJob).isAfter(ctx.parent.startDateJob)
      },
    })
    .nullable(),
  name: yup
    .string()
    .min(10, 'O campo deve conter no minimo 10 caracteres')
    .required('Nome é obrigatório!'),
  email: yup
    .string()
    .email('Por favor, informe um e-mail válido!')
    .max(50, 'O e-mail deve conter no máximo 50 caracteres!')
    .required('E-mail é obrigatório'),
  email2: yup
    .string()
    .email('Por favor, informe um e-mail válido!')
    .max(50, 'O e-mail deve conter no máximo 50 caracteres!'),
  cpf: yup
    .string()
    .test({
      message: 'CPF inválido',
      test: cpf => validaCPF(cpf),
    })
    .required('CPF é obrigatório!'),
  rg: yup.string().required('RG é obrigatório!'),
  specialty: yup
    .string()
    .max(100, 'O campo deve conter no máximo 100 caracteres.'),
  seniority: yup.string().nullable(),
  educationLevel: yup.string().nullable(),
  phone: yup
    .string()
    .matches(validatePhoneRegex, {
      excludeEmptyString: true,
      message: 'Telefone inválido',
    })
    .required('Telefone 01 é obrigatório!'),
  phone2: yup.string().matches(validatePhoneRegex, {
    excludeEmptyString: true,
    message: 'Telefone inválido',
  }),
  phone3: yup.string().matches(validatePhoneRegex, {
    excludeEmptyString: true,
    message: 'Telefone inválido',
  }),
  phoneEmergency: yup.string().matches(validatePhoneRegex, {
    excludeEmptyString: true,
    message: 'Telefone inválido',
  }),
  address: yup
    .string()
    .max(150, 'A cidade deve conter no máximo 30 caracteres!')
    .required('Endereço é obrigatório!'),
  district: yup
    .string()
    .max(30, 'A cidade deve conter no máximo 30 caracteres!')
    .required('Bairro é obrigatório!'),
  cep: yup.string().required('CEP é obrigatório!'),
  city: yup
    .string()
    .max(30, 'A cidade deve conter no máximo 30 caracteres!')
    .required('Cidade é obrigatório!'),
  typeContract: yup.string().nullable(),
  stateEnum: yup.string().required('Estado obrigatório!'),
  idOffice: yup.string().required('Cargo é obrigatório!'),
})

const validationSchemaDelete = yup.object({})

function uppercaseName(name) {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
}

/**
 * User form
 */
const Form = ({
  listTipContratacao,
  listTipSenioridade,
  listTipEscolaridade,
  listTipState,
  isDeleteAction = false,
  isUpdateAction = false,
  listOffices = [],
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
  onOpenDateModal,
  onCloseDateModal,
}) => {
  const [offices, setOffices] = useState([])


  const verifyDocumentCpf = async (cpf) => {
    try {
    const response = await api.get(`/employee/verifycation-document-cpf?cpf=${cpf}`);
    const hasInDatabase = response.data;
    console.log("Has validado ", hasInDatabase)
    return hasInDatabase;

    }catch (error) {
      console.error(error);
    }
  };

  const verifyDocumentRg = async (rg) => {
    try {
    const response = await api.get(`/employee/verifycation-document-rg?rg=${rg}`);
    const hasInDatabase = response.data;
    console.log("Has validado ", hasInDatabase)
    return hasInDatabase;

    }catch (error) {
      console.error(error);
    }
  };



  const formik = useFormik({
    initialValues: {
      id: param.id,
      cpf: param.cpf ? Mask.toPattern(param.cpf, '999.999.999-99') : '',
      name: uppercaseName(param.name),
      rg: param.rg ? param.rg : '',
      birthdayDate: param.birthdayDate || null,
      seniority: param.seniority || '',
      stateEnum: param.stateEnum,
      educationLevel: param.educationLevel || '',
      specialty: param.specialty,
      phone: param.phone ? createPhoneNumberMask(param.phone) : '',
      phone2: param.phone2 ? createPhoneNumberMask(param.phone2) : '',
      phone3: param.phone3 ? createPhoneNumberMask(param.phone3) : '',
      phoneEmergency: param.phoneEmergency
        ? createPhoneNumberMask(param.phoneEmergency)
        : '',
      email: param.email,
      email2: param.email2,
      address: param.address,
      district: param.district,
      cep: param.cep ? Mask.toPattern(param.cep, '99.999-999') : '',
      city: param.city,
      startDateJob: param.startDateJob || null,
      endDateJob: param.endDateJob || null,
      typeContract: param.typeContract || null,
      idOffice: param.idOffice ? param.idOffice.id : '',
    },
    validationSchema: isUpdateAction
      ? validationSchemaEdit
      : isDeleteAction
      ? validationSchemaDelete
      : validationSchemaSave,
    onSubmit: async(values,  { setErrors }) => {

      if(isDeleteAction === false && isUpdateAction === false){
      const isValidCpf = await verifyDocumentCpf(values.cpf)

      if(isValidCpf === true ){
        setErrors({ cpf: 'O CPF já existe.' })
        return
      }

      const isValidRg = await verifyDocumentRg(values.rg)
      if(isValidRg === true ){
        setErrors({ rg: 'O RG já existe.' })
        return
      }
    }


    if( isUpdateAction === true){
      console.log("Parametro ", removeSpecialCharacters(values.cpf))
      const isValidCpf = await verifyDocumentCpf(values.cpf)

      if(isValidCpf === true && param.cpf !== removeSpecialCharacters(values.cpf)){
        setErrors({ cpf: 'O CPF já existe.' })
        return
      }
    const isValidRg = await verifyDocumentRg(values.rg)
    if(isValidRg === true && param.rg !== removeSpecialCharacters(values.rg)){
      setErrors({ rg: 'O RG já existe.' })
      return
    }
  }
  console.log('gggggggggggggg: ', values)
      onSubmit(values)
    },
  })

  const handleChangeSenioridade = ev => {
    formik.handleChange(ev)
    if (ev.target.value !== 'ESTAGIARIO') {
      formik.setFieldValue('typeContract', null)
      formik.setFieldTouched('typeContract', false)
    }
    if (ev.target.value === 'ESTAGIARIO') {
      formik.setFieldValue('typeContract', 'ESTAGIO')
    }
  }

  useEffect(() => {
    setOffices(
      listOffices.map(office => ({ key: office.id, value: office.desCargo }))
    )
  }, [listOffices])

  const classes = makeStyles({
    container: {
      marginTop: 50,
    },
  })()

  const isContratoFieldDisabled = formik.values.seniority === 'ESTAGIARIO'
  const listTipContratacaoFilteredBySeniority = listTipContratacao.filter(
    contrato => {
      const { seniority } = formik.values
      if (!seniority) return true
      if (seniority === 'ESTAGIARIO') {
        return contrato.key === 'ESTAGIO'
      } else {
        return contrato.key !== 'ESTAGIO'
      }
    }
  )

  const resolveEsco = () => {
    const list = listTipEscolaridade.find(
      item => item.key === formik.values.educationLevel
    )

    return list ? list.value : ''
  }

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      {!isDeleteAction && (
        <>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="name"
                name="name"
                label="Nome"
                value={capitalizeFirstLetterOfEachWord(formik.values.name)}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{
                  maxLength: 150,
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="email"
                name="email"
                label="E-mail"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                inputProps={{
                  maxLength: 70,
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="email2"
                name="email2"
                label="E-mail 2"
                value={formik.values.email2}
                onChange={formik.handleChange}
                error={formik.touched.email2 && Boolean(formik.errors.email2)}
                helperText={formik.touched.email2 && formik.errors.email2}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="cpf"
                name="cpf"
                label="CPF"
                value={formik.values.cpf}
                onChange={e => {
                  const event = e
                  event.target.value = Mask.toPattern(
                    e.target.value,
                    '999.999.999-99'
                  )
                  formik.handleChange(event)
                }}
                error={formik.touched.cpf && !!formik.errors.cpf}
                helperText={formik.touched.cpf && formik.errors.cpf}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="rg"
                name="rg"
                label="RG"
                value={formik.values.rg}
                onChange={e => {
                  const event = e
                  event.target.value = e.target.value
                  formik.handleChange(event)
                }}
                error={formik.touched.rg && Boolean(formik.errors.rg)}
                helperText={formik.touched.cpf && formik.errors.rg}
                inputProps={{
                  maxLength: 20,
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <Datepicker
                autoFocus
                fullWidth
                id="birthdayDate"
                name="birthdayDate"
                label="Data Nascimento"
                value={formik.values.birthdayDate}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent(
                      'birthdayDate',
                      moment(val).toISOString()
                    )
                  )
                }
                error={
                  formik.touched.birthdayDate &&
                  Boolean(formik.errors.birthdayDate)
                }
                helperText={
                  formik.touched.birthdayDate && formik.errors.birthdayDate
                }
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <SelectAutocompleteField
                label="Senioridade"
                value={formatTextUser(formik.values.seniority)}
                options={listTipSenioridade.map(contrato =>
                  formatTextUser(contrato.value)
                )}
                onChange={newValue => {
                  handleChangeSenioridade({
                    target: {
                      name: 'seniority',
                      value: formatarCargo(newValue),
                    },
                  })
                }}
                error={
                  formik.touched.seniority && Boolean(formik.errors.seniority)
                }
                helperText={formik.touched.seniority && formik.errors.seniority}
                defaultValue=""
                menuProps={{
                  MenuProps: {
                    variant: 'menu',
                  },
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <SelectAutocompleteField
                label="Escolaridade"
                value={resolveEsco()}
                options={listTipEscolaridade.map(item => item.value)}
                onChange={newValue => {
                  console.log('Valor selecionado:', newValue)

                  const selectedOption = listTipEscolaridade.find(
                    item => item.value === newValue
                  )

                  console.log('Opção selecionada:', selectedOption)

                  formik.setFieldValue(
                    'educationLevel', selectedOption.key
                  )
                }}
                error={
                  formik.touched.educationLevel &&
                  Boolean(formik.errors.educationLevel)
                }
                helperText={
                  formik.touched.educationLevel && formik.errors.educationLevel
                }
                defaultValue=""
                menuProps={{
                  MenuProps: {
                    variant: 'menu',
                  },
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="specialty"
                name="specialty"
                label="Especialidades"
                value={formik.values.specialty}
                onChange={formik.handleChange}
                error={
                  formik.touched.specialty && Boolean(formik.errors.specialty)
                }
                helperText={formik.touched.specialty && formik.errors.specialty}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="phone"
                name="phone"
                label="Fone"
                value={formik.values.phone}
                onChange={e => {
                  e.target.value = createPhoneNumberMask(e.target.value)
                  formik.handleChange(e)
                }}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="phone2"
                name="phone2"
                label="Fone 2"
                value={formik.values.phone2}
                onChange={e => {
                  e.target.value = createPhoneNumberMask(e.target.value)
                  formik.handleChange(e)
                }}
                error={formik.touched.phone2 && !!formik.errors.phone2}
                helperText={formik.touched.phone2 && formik.errors.phone2}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="phone3"
                name="phone3"
                label="Fone 3"
                value={formik.values.phone3}
                onChange={e => {
                  e.target.value = createPhoneNumberMask(e.target.value)
                  formik.handleChange(e)
                }}
                error={formik.touched.phone3 && !!formik.errors.phone3}
                helperText={formik.touched.phone3 && formik.errors.phone3}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="phoneEmergency"
                name="phoneEmergency"
                label="Fone Emergência"
                value={formik.values.phoneEmergency}
                onChange={e => {
                  e.target.value = createPhoneNumberMask(e.target.value)
                  formik.handleChange(e)
                }}
                error={
                  formik.touched.phoneEmergency &&
                  !!formik.errors.phoneEmergency
                }
                helperText={
                  formik.touched.phoneEmergency && formik.errors.phoneEmergency
                }
                inputProps={{
                  maxLength: 200,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="address"
                name="address"
                label="Endereço"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && !!formik.errors.address}
                helperText={formik.touched.address && formik.errors.address}
                inputProps={{
                  maxLength: 70,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="district"
                name="district"
                label="Bairro"
                value={formik.values.district}
                onChange={formik.handleChange}
                error={formik.touched.district && !!formik.errors.district}
                helperText={formik.touched.district && formik.errors.district}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="cep"
                name="cep"
                label="CEP"
                value={formik.values.cep}
                onChange={e => {
                  const evnt = e
                  evnt.target.value = Mask.toPattern(
                    e.target.value,
                    '99.999-999'
                  )
                  formik.handleChange(evnt)
                }}
                error={formik.touched.cep && !!formik.errors.cep}
                helperText={formik.touched.cep && formik.errors.cep}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <TextField
                autoFocus
                fullWidth
                id="city"
                name="city"
                label="Cidade"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && !!formik.errors.city}
                helperText={formik.touched.city && formik.errors.city}
                inputProps={{
                  maxLength: 70,
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    variant: 'menu',
                  },
                }}
                id="stateEnum"
                name="stateEnum"
                label="Estado"
                value={formik.values.stateEnum}
                onChange={formik.handleChange}
                error={formik.touched.stateEnum && !!formik.errors.stateEnum}
                helperText={formik.touched.stateEnum && formik.errors.stateEnum}
              >
                {listTipState.map(stateEnum => (
                  <MenuItem key={stateEnum.key} value={stateEnum.key}>
                    {stateEnum.value}
                  </MenuItem>
                ))}
              </TextField>
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <Datepicker
                autoFocus
                fullWidth
                id="startDateJob"
                name="startDateJob"
                label="Data Admissão"
                value={formik.values.startDateJob}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent(
                      'startDateJob',
                      moment(val).toISOString()
                    )
                  )
                }
                error={
                  formik.touched.startDateJob &&
                  Boolean(formik.errors.startDateJob)
                }
                helperText={
                  formik.touched.startDateJob && formik.errors.startDateJob
                }
                autoOk
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <Datepicker
                autoFocus
                fullWidth
                id="endDateJob"
                name="endDateJob"
                label="Data Demissão"
                value={formik.values.endDateJob}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={val => {
                  if (val instanceof Date && !isNaN(val)) {
                    formik.handleChange(
                      createSyntheticEvent(
                        'endDateJob',
                        moment(val).toISOString()
                      )
                    )
                  } else if (val === null) {
                    formik.handleChange(
                      createSyntheticEvent(
                        'endDateJob',
                        moment(val).toISOString()
                      )
                    )
                  } else {
                    formik.handleChange(
                      createSyntheticEvent('endDateJob', moment('Invalid Date'))
                    )
                  }
                }}
                error={formik.touched.endDateJob && !!formik.errors.endDateJob}
                helperText={
                  formik.touched.endDateJob && formik.errors.endDateJob
                }
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <SelectAutocompleteField
                label="Contrato"
                value={
                  formik.values.typeContract === 'NAO_CADASTRADO'
                    ? ''
                    : formik.values.typeContract
                }
                options={listTipContratacaoFilteredBySeniority.map(
                  contrato => contrato.value
                )}
                onChange={newValue => {
                  console.log('Contrato', newValue)
                  if (newValue === null || newValue === '') {
                    newValue = 'NAO_CADASTRADO'
                  }
                  formik.handleChange({
                    target: {
                      name: 'typeContract',
                      value: formatarCargo(newValue),
                    },
                  })
                }}
                error={
                  formik.touched.typeContract &&
                  Boolean(formik.errors.typeContract)
                }
                helperText={
                  formik.touched.typeContract && formik.errors.typeContract
                }
                disabled={isContratoFieldDisabled}
                menuProps={{
                  MenuProps: {
                    variant: 'menu',
                  },
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <TextField
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    className: classes.container,
                    variant: 'menu',
                  },
                }}
                id="idOffice"
                name="idOffice"
                label="Cargo"
                value={formik.values.idOffice}
                defaultValue={''}
                onChange={formik.handleChange}
                error={
                  formik.touched.idOffice && Boolean(formik.errors.idOffice)
                }
                helperText={formik.touched.idOffice && formik.errors.idOffice}
              >
                {offices.map(office => (
                  <MenuItem key={office.key} value={office.key}>
                    {office.value}
                  </MenuItem>
                ))}
                <br />
              </TextField>
            </FlexItemWrapper>
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>

        <FlexItemWrapper right>
          <Button
            color={primaryButtonColor}
            label={primaryButtonLabel}
            type="submit"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  beforeSubmit: PropTypes.func,
  isDeleteAction: PropTypes.bool,
  listOffices: PropTypes.array,
  onCancel: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  setter: PropTypes.func,
  values: PropTypes.object,
  onOpenDateModal: PropTypes.func.isRequired,
  onCloseDateModal: PropTypes.func.isRequired,
}

export default Form
