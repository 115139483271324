

export function formatTextUser(inputText) {

  if(inputText === null){
    return null;
  }

  const formattedText = inputText.replace(/_/g, ' ');
  return formattedText;
}

export function formatarCargo(cargo) {
  if(cargo === null){
    return null
  }

  return cargo
    .replace(/[^\w\s]/gi, '')
    .toUpperCase()
    .replace(/\s+/g, '_')
}

export function isDateValid(dateString) {
  const dateRegex = /^[\w]{3} [\w]{3} [\d]{1,2} [\d]{4} [\d]{2}:[\d]{2}:[\d]{2} [A-Z]{3}-[\d]{4} \([\w\s]+\)$/;

  if (!dateRegex.test(dateString)) {
    return false; // Formato inválido
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return false; // Data inválida
  }

  return true;
}


export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].value !== arr2[i].value || arr1[i].label !== arr2[i].label) {
      return false;
    }
  }
  return true;
}

export function capitalizeFirstLetterOfEachWord(str) {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}




export function removeSpecialCharacters(inputString) {
  return inputString.replace(/[^a-zA-Z0-9]/g, '');
}

export function formatarEscolaridade(escolaridade) {
  if (escolaridade === null) {
    return null;
  }
console.log(escolaridade)
  return escolaridade
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\p{L}\p{N}\s]/gu, '')
    .toUpperCase()
    .replace(/\s+/g, '_');
}

export function formatarEscolaridadeManterAcento(escolaridade) {
  if (escolaridade === null) {
    return null;
  }

  return escolaridade.normalize('NFD').toUpperCase();
}
