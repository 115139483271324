import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import * as yup from 'yup'
import { useFormik } from 'formik'

import createSyntheticEvent from '../../../utils/createSyntheticEvent'
import MaterialTextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Button from '../../../utils/Form/Button'
import Datepicker from '../../../utils/Form/Datepicker'
import Hourpicker from '../../../utils/Form/Hourpicker'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import { Grid, Tooltip } from '@material-ui/core'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      maxLength={4}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      suffix={'%'}
      isNumericString={true}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const validationSchema = yup.object({
  idActivity: yup.number().required('Por favor, escolha a pontuação!'),
  txtDescricao: yup
    .string()
    .max(4000, 'A sigla da atividade deve conter no máximo 4000 caracteres!')
    .required('Por favor, informe uma descrição!'),

  hrInicial: yup
    .date()
    .required('Por favor, informe uma hora inicial válida!')
    .nullable(),
  hrFinal: yup
    .date()
    .required('Por favor, informe uma hora final válida!')
    .nullable(),

  dthLancamento: yup
    .date()
    .required('Por favor, informe uma data válida de lançamento!')
    .nullable(),
})

/**
 * Period form
 */
const Form = ({
  disabled,
  activities = [],
  allActivities = [],
  periods = [],
  isDeleteAction = false,
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  sendingForm = false,
  searchingActivities = false,
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
  onOpenDateModal = () => {},
  onCloseDateModal = () => {},
  dataInicialFiltro = [],
  dataFinalFiltro = [],
}) => {
  const { id: userIdLogged } = JSON.parse(localStorage.getItem('user'))

  const inputDateRef = useRef()

  const [mineOnly, setMineOnly] = useState(false)
  const [currentActivity, setActivity] = useState({})
  const [hasSelectedActivty, setHasSelectedActivity] = useState(false)
  const [idActivity, setIdActivity] = useState(null)
  const [openTooltip, setOpenTooltip] = useState(false)
  const [listActivities, setListActivities] = useState('')
  const formik = useFormik({
    initialValues: {
      id: param.id,
      idActivity: param.idActivity,
      idUser: param.idUser,
      hrInicial: param.hrInicial,
      hrFinal: param.hrFinal,
      dthLancamento:
        dataInicialFiltro.toString() === dataFinalFiltro.toString()
          ? new Date(dataInicialFiltro)
          : param.dthLancamento,
      txtDescricao: param.txtDescricao,
      tipExecucao: param.tipExecucao,
      tipStatus: param.activity ? param.activity.tipStatus : '',
      indFinalizado: param.indFinalizado,
      perConclusao: param.perConclusao,
      totalHoras: param.totalHoras,
      tpSub: '',
    },
    validationSchema: validationSchema,
    validate: values => {
      const errors = {}
      let { hrInicial, hrFinal, dthLancamento } = values

      if (moment(hrInicial).isValid() && moment(hrFinal).isValid()) {
        const ini = moment(values.hrInicial)
          .format()
          .split('T')
        const fim = moment(values.hrFinal)
          .format()
          .split('T')

        hrInicial = moment(values.hrInicial)
        hrFinal = moment(`${ini[0]}T${fim[1]}`)

        if (!hrFinal.isAfter(hrInicial)) {
          errors.hrInicial = 'Hora inicial deve ser menor que a hora final!'
          errors.hrFinal = 'Hora final deve ser maior que a hora inicial!'
        }
      }

      if (periods.length > 0) {
        let entriesRecords = periods.map(period => {
          const dtaInicio = period.dtaInicio
            .split('/')
            .reverse()
            .join('-')
          const dtaFim = period.dtaFim
            .split('/')
            .reverse()
            .join('-')

          return moment(dthLancamento).isBetween(
            moment(dtaInicio),
            moment(dtaFim),
            'days',
            '[]'
          )
            ? true
            : 'Período fechado ou não existe!'
        })

        entriesRecords.filter(record => record === true).length === 0 &&
          entriesRecords
            .filter(record => record !== true)
            .map(record => {
              return (errors.dthLancamento = record)
            })
      } else {
        errors.dthLancamento = 'Período fechado, ou não existe!'
      }

      return errors
    },
    onSubmit: values => {
      if (
        (!values.idActivity && idActivity) ||
        values.idActivity !== idActivity
      ) {
        values.idActivity = idActivity
      }

      if (values.dthLancamento) {
        if (values.hrInicial) {
          values.hrInicial = new Date(values.hrInicial).setSeconds(0)
          values.hrInicial = moment(values.dthLancamento).format('YYYY-MM-DD') +
            'T' +
            moment(values.hrInicial).format('HH:mm:ss')
        }

        if (values.hrFinal) {
          values.hrFinal = new Date(values.hrFinal).setSeconds(0)
          values.hrFinal = moment(values.dthLancamento).format('YYYY-MM-DD') +
            'T' +
            moment(values.hrFinal).format('HH:mm:ss')
        }
      }

      if (values.tpSub === 'limpandoDuplicando') {
        onSubmit(values, function() {
          handleClearAll(values)
        })
      } else {
        onSubmit(values)
      }
    },
  })

  useEffect(() => {
    formik.resetForm()
    formik.setValues({
      ...param,
      dthLancamento:
        dataInicialFiltro.toString() === dataFinalFiltro.toString()
          ? new Date(dataInicialFiltro)
          : param.dthLancamento,
    })
  }, [param])

  const handleActivityChange = ({ key: id }) => {
    const act = activities.find(({ id: aId }) => id === aId)
    if (act !== undefined) {
      setActivity(act)
      setHasSelectedActivity(true)
      setIdActivity(id)
    }
  }

  const handleClearActivity = () => {
    setHasSelectedActivity(false)
    setActivity({})
    setIdActivity(null)
  }

  const handleClearAll = values => {

    values.hrInicial = null
    values.hrFinal = null
    values.txtDescricao = ''
    setHasSelectedActivity(false)
    setActivity({})
    setIdActivity(null)
  }

  useEffect(() => {
    if (param.idActivity) {
      const act = allActivities.find(({ id: aId }) => param.idActivity === aId)
      if (act !== undefined) {
        setActivity(act)
        setIdActivity(param.idActivity)
        setHasSelectedActivity(true)
      }
    }
  }, [param.idActivity])

  useEffect(() => {
    const activityFiltered = (activities || []).filter(
      ({ userId }) => userId === userIdLogged
    )

    const activitiesList = (mineOnly ? activityFiltered : activities || []).map(
      ({ id, sigAtividade, desAtividade, desUsuario }) => ({
        key: id,
        value: `${sigAtividade} - ${desAtividade} - ${desUsuario}`,
      })
    )
    if (activitiesList !== undefined || activitiesList.length !== 0) {
      setListActivities(activitiesList)
    }
  }, [activities, mineOnly])

  useEffect(() => {
    if (!isDeleteAction && hasSelectedActivty) {
      inputDateRef.current.focus()
    }
  }, [hasSelectedActivty])

  useEffect(() => {
    if (formik.values.tpSub === 'deletar' && isDeleteAction) {
      onSubmit(formik.values)
    }
  }, [formik.values.tpSub])

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        if (!isDeleteAction) formik.handleSubmit()
      }}
    >
      {!isDeleteAction && (
        <>
          {!hasSelectedActivty ? (
            <>
              <FlexWrapper>
                <FlexItemWrapper>
                  <Autocomplete
                    disableClearable
                    fullWidth
                    noOptionsText={
                      searchingActivities
                        ? 'Aguarde, carregando suas atividades...'
                        : 'Sem registro de atividade, cadastre uma atividade para escolher!'
                    }
                    clearOnBlur={false}
                    options={listActivities}
                    value={formik.values.idActivity}
                    getOptionLabel={option => option.value}
                    onChange={(e, v) => handleActivityChange(v)}
                    renderInput={params => (
                      <MaterialTextField
                        {...params}
                        label="Atividade*"
                        autoFocus={!hasSelectedActivty}
                      />
                    )}
                  />
                </FlexItemWrapper>
                <Grid item xs={2}>
                  <FlexItemWrapper>
                    <FormControlLabel
                      label="Minhas atividades"
                      style={{ paddingTop: 13 }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={mineOnly}
                          onChange={event => setMineOnly(event.target.checked)}
                        />
                      }
                    />
                  </FlexItemWrapper>
                </Grid>
              </FlexWrapper>
            </>
          ) : (
            <>
              <FlexWrapper>
                <FlexItemWrapper>
                  <MaterialTextField
                    fullWidth
                    disabled
                    id="sigAtividade"
                    label="Sigla da Atividade"
                    value={currentActivity.sigAtividade}
                  />
                </FlexItemWrapper>
                <FlexItemWrapper>
                  <MaterialTextField
                    fullWidth
                    disabled
                    id="desAtividade"
                    label="Descrição da Atividade"
                    value={currentActivity.desAtividade}
                  />
                </FlexItemWrapper>
              </FlexWrapper>
              <FlexWrapper>
                <FlexItemWrapper>
                  <MaterialTextField
                    fullWidth
                    disabled
                    id="desProjeto"
                    label="Nome do Projeto"
                    value={currentActivity.desProjeto}
                  />
                </FlexItemWrapper>
                <FlexItemWrapper>
                  <MaterialTextField
                    fullWidth
                    disabled
                    id="descClient"
                    label="Nome do Cliente"
                    value={currentActivity.descClient}
                  />
                </FlexItemWrapper>
                <FlexItemWrapper>
                  <MaterialTextField
                    fullWidth
                    disabled
                    id="desUsuario"
                    label="Destinatário"
                    value={currentActivity.desUsuario}
                  />
                </FlexItemWrapper>
              </FlexWrapper>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <b style={{ cursor: 'pointer' }} onClick={handleClearActivity}>
                  LIMPAR
                </b>
                <DeleteIcon
                  style={{ cursor: 'pointer' }}
                  onClick={handleClearActivity}
                />
              </div>
            </>
          )}
          <FlexWrapper>
            <FlexItemWrapper>
              <Datepicker
                disabled={disabled}
                id="dthLancamento"
                inputRef={inputDateRef}
                label="Data de lançamento"
                value={formik.values.dthLancamento}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent(
                      'dthLancamento',
                      moment(val).toISOString()
                    )
                  )
                }
                error={
                  formik.touched.dthLancamento &&
                  Boolean(formik.errors.dthLancamento)
                }
                helperText={
                  formik.touched.dthLancamento && formik.errors.dthLancamento
                }
                autoOk
              />
            </FlexItemWrapper>

            <FlexItemWrapper middle>
              <Hourpicker
                disabled={disabled}
                id="hrInicial"
                label="Hora inicial"
                value={formik.values.hrInicial}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent(
                      'hrInicial',
                      moment(val).isValid()
                        ? moment(val).format('YYYY-MM-DDTHH:mm:ss')
                        : null
                    )
                  )
                }
                autoOk={true}
                error={
                  formik.touched.hrInicial && Boolean(formik.errors.hrInicial)
                }
                helperText={formik.touched.hrInicial && formik.errors.hrInicial}
              />
            </FlexItemWrapper>

            <FlexItemWrapper right>
              <Hourpicker
                disabled={disabled}
                id="hrFinal"
                label="Hora final"
                value={formik.values.hrFinal}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent(
                      'hrFinal',
                      moment(val).isValid()
                        ? moment(val).format('YYYY-MM-DDTHH:mm:ss')
                        : null
                    )
                  )
                }
                autoOk={true}
                error={formik.touched.hrFinal && Boolean(formik.errors.hrFinal)}
                helperText={formik.touched.hrFinal && formik.errors.hrFinal}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <MaterialTextField
                fullWidth
                inputProps={{
                  maxLength: 255,
                }}
                multiline
                rows={5}
                variant="outlined"
                id="txtDescricao"
                name="txtDescricao"
                label="Descrição"
                value={formik.values.txtDescricao}
                onChange={formik.handleChange}
                error={
                  formik.touched.txtDescricao &&
                  Boolean(formik.errors.txtDescricao)
                }
                helperText={
                  formik.touched.txtDescricao && formik.errors.txtDescricao
                }
              />
            </FlexItemWrapper>
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>
        {isDeleteAction || formik.values.id != null ? (
          ''
        ) : (
          <>
            <FlexItemWrapper>
              <Button
                color={primaryButtonColor}
                label={
                  sendingForm ? 'Salvando e Duplicando' : 'Duplicar Lançamento'
                }
                type="submit"
                onClick={() =>
                  formik.setFieldValue('tpSub', 'duplicando') &&
                  isDeleteAction &&
                  onSubmit(formik.values)
                }
                disabled={
                  (idActivity ? false : true) ||
                  sendingForm ||
                  param.atividadeNaoProprietaria
                }
              ></Button>
            </FlexItemWrapper>
            <FlexItemWrapper>
              <Button
                color={primaryButtonColor}
                label={sendingForm ? 'Salvando...' : 'Novo Lançamento'}
                type="submit"
                onClick={() =>
                  formik.setFieldValue('tpSub', 'limpandoDuplicando') &&
                  isDeleteAction &&
                  onSubmit(formik.values)
                }
                disabled={
                  (idActivity ? false : true) ||
                  sendingForm ||
                  param.atividadeNaoProprietaria
                }
              ></Button>
            </FlexItemWrapper>
          </>
        )}
        {isDeleteAction ?
          (<FlexItemWrapper right>
            <Tooltip
              open={!idActivity ? openTooltip : false}
              onClose={() => setOpenTooltip(false)}
              onOpen={() => setOpenTooltip(true)}
              title="Por favor, selecione uma atividade!"
              interactive
            >
              <span>
                <Button
                  color={primaryButtonColor}
                  label={sendingForm ? 'Salvando...' : primaryButtonLabel}
                  type="submit"
                  onClick={() =>
                    formik.setFieldValue('tpSub', 'deletar') &&
                    isDeleteAction
                  }
                  disabled={
                    (idActivity ? false : true) ||
                    sendingForm ||
                    param.atividadeNaoProprietaria
                  }
                ></Button>
              </span>
            </Tooltip>
          </FlexItemWrapper>) : (
            <FlexItemWrapper right>
              <Tooltip
                open={!idActivity ? openTooltip : false}
                onClose={() => setOpenTooltip(false)}
                onOpen={() => setOpenTooltip(true)}
                title="Por favor, selecione uma atividade!"
                interactive
              >
                <span>
                  <Button
                    color={primaryButtonColor}
                    label={sendingForm ? 'Salvando...' : primaryButtonLabel}
                    type="submit"
                    onClick={() =>
                      formik.setFieldValue('tpSub', 'salvar') &&
                      isDeleteAction &&
                      onSubmit(formik.values)
                    }
                    disabled={
                      (idActivity ? false : true) ||
                      sendingForm ||
                      param.atividadeNaoProprietaria
                    }
                  ></Button>
                </span>
              </Tooltip>
            </FlexItemWrapper>)}
      </FlexWrapper>
    </form>
  )
}

const mapStateToProps = state => {
  const {
    loginReducer: { userInfo },
  } = state
  return {
    userInfo,
  }
}

export default connect(mapStateToProps)(Form)
